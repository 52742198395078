import React, { Component } from 'react';
import GoogleMap from 'google-map-react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Row } from 'reactstrap';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { withRouter } from 'react-router-dom';

import MegaMapHelper from '../map-helper/mega-map-helper';
import MapDrawing from '../map-helper/map-drawing';
import MapPolygon from '../map-helper/map-polygon';

import {
  GeofenceAdd,
  GeofenceUpdate,
  GeofenceDelete,
} from '../api-tasks/geofence-changes';
import { getTextToSpeech } from '../api-tasks/ai';
import { NotificationManager } from '../components/common/react-notifications';
import { Colxx } from '../components/common/CustomBootstrap';
import { geofenceSelectRequest } from '../redux/actions';
import LiveMapInfoBox from './LiveMapInfoBox';
import { TimeSince } from '../helpers/TimeSince';
import { addAlertManagerItem } from '../helpers/AlertManager';
import MegaMapSideList from './MegaMapSideList';
import IntlMessages from '../helpers/IntlMessages';

import MegaMapGeoVehiclesModel from "../components/MegaMapGeoVehiclesModel";
import SuspensionModel from "../components/SuspensionModel";

import { favoritePlacesSelectRequest } from "../redux/actions";
import {
  FavoritePlaceAdd,
  FavoritePlacesDelete
} from "../api-tasks/favorite-places";
import MapFavoritePlaces from "../map-helper/map-favorite-places";


// ---------Geofence functions starts here ------------------------
function trim(s, c) {
  // eslint-disable-next-line no-param-reassign
  if (c === ']') c = '\\]';
  // eslint-disable-next-line no-param-reassign
  if (c === '\\') c = '\\\\';
  return s.replace(new RegExp(`^[${c}]+|[${c}]+$`, 'g'), '');
}

function extractFence(fence) {
  const localFence = fence;
  let dataString = trim(localFence, ')');
  dataString = trim(dataString, '(');

  const dataArr = dataString.split(')(');
  const geoArr = [];
  let foo;
  dataArr.forEach((e) => {
    foo = e.split(',');
    geoArr.push([parseFloat(foo[0]), parseFloat(foo[1])]);
  });

  return geoArr;
}

function isPolyInside(point, vs) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  const x = point[0];
  const y = point[1];

  let inside = false;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0];
    const yi = vs[i][1];
    const xj = vs[j][0];
    const yj = vs[j][1];

    // eslint-disable-next-line no-mixed-operators
    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) inside = !inside;
  }

  return inside;
}

function getGeofenceWatcher() {
  return {
    inStatus: 0,
    anyChange(status) {
      if (this.inStatus === 0) {
        this.inStatus = (status ? 2 : 1);
        return false;
      } if (this.inStatus !== (status ? 2 : 1)) {
        this.inStatus = (status ? 2 : 1);
        return true;
      }
      this.inStatus = (status ? 2 : 1);
      return false;
    },
  };
}

function getFencePlan(geofences) {

  if (geofences && !geofences.length) { return null; }

  const fenceEx = geofences.map(x => ({
    ...x,
    liveStatus: null,
    isSpeeding: false,
    speedingItem: {},
    watcher: getGeofenceWatcher(),
    boundariesPoints: extractFence(x.points),
  }));

  return fenceEx;
}

// ---------Geofence functions ends here ------------------------

class MegaMap extends Component {
  handleMapMarkerClick = (autoid) => {
    this.handleOnClickVehicle(autoid, true);
  };

  constructor() {
    super();

    this.megaMapHelper = MegaMapHelper.init(this.handleMapMarkerClick);
    this.mapDrawing = null;

    const userMuted = localStorage.getItem('user_mute_alert');

    this.liveMapNoDataInMillisecond = 2880 * 60 * 1000;

    this.state = {
      mapModelOpen: false,
      selectedVehicles: [],
      vehicles: [],
      mapVehicles: [],
      vehicles_combo: [],
      mapBoxes: [],
      subscriber_id: 0,
      geofencePoints: [],
      filteredDataRows: [],
      running: 0,
      idle: 0,
      off: 0,
      noDataCount: 0,
      expiredCount:0,
      expiryExtensionCount : 0,
      showSideBar: true,
      showGeofenceVehicles: false,
      searchText: '',

      muteAlert: userMuted === 'false' || !userMuted ? false : userMuted,
      userInteracted: false,
    };
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.onInteracted);
    this.megaMapHelper.distroy();
  }

  UNSAFE_componentWillMount() {
    this.megaMapHelper = MegaMapHelper.init(this.handleMapMarkerClick);
  }

  componentDidCatch() { }

  componentDidMount() {
    document.body.addEventListener('click', this.onInteracted);

    this.megaMapHelper.setVehicles(this.props.vehiclesAll);
    this.setState({ selectedVehicles: this.props.vehiclesAll || [] });

    if (this.props.selectedVehicles.length === 1) {
      this.setState({ selectedVehicles: this.props.selectedVehicles });
    }

    if (this.props.subscriberDetails) {
      const attributes = this.props.subscriberDetails.attributes;
      const noDataMin = parseInt(attributes.liveMap.noDataMin, 10);
      this.liveMapNoDataInMillisecond = noDataMin * 60 * 1000;
    }
  }

  componentDidUpdate(prevProps) {
    try {
      if (this.props.subscriberDetails !== prevProps.subscriberDetails) {
        const attributes = this.props.subscriberDetails.attributes;
        const noDataMin = parseInt(attributes.liveMap.noDataMin, 10);
        this.liveMapNoDataInMillisecond = noDataMin * 60 * 1000;
      }

      if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
        // const vehicles = this.props.vehiclesAll || [];
        this.megaMapHelper.setVehicles(this.props.vehiclesAll);
        this.setState({
          selectedVehicles: this.props.vehiclesAll || [],
          // mapBoxes: vehicles.length < 13 ? vehicles.map(x => x.autoid) : []
          mapBoxes: [],
        });

        if (this.props.selectedVehicles.length === 1) {
          this.setState({ selectedVehicles: this.props.selectedVehicles });
        }
      }

      if (
        prevProps.liveUpdates !== this.props.liveUpdates &&
        this.state.mapsLoaded
      ) {
        this.megaMapHelper.setLastUpdates(
          this.props.liveUpdates,
          this.liveMapNoDataInMillisecond
        );

        this.attachLiveDetails(this.props.liveUpdates);
        // console.log('the selected ', this.state.selectedVehicles);
      }

      if (prevProps.selectedVehicles !== this.props.selectedVehicles) {
        this.handleOnSelectedVehicleChange(this.props.selectedVehicles);

        if (this.props.selectedVehicles.length) {
          this.setState({
            selectedVehicles: this.props.selectedVehicles,
            mapBoxes:
              this.props.selectedVehicles.length < 13
                ? this.props.selectedVehicles.map((x) => x.autoid)
                : [],
          });
        } else {
          this.setState({ selectedVehicles: this.props.vehiclesAll || [] });
        }
      }

      if (prevProps.geofenceAll !== this.props.geofenceAll) {
        this.generateGeofencePoints();
      }

      if (prevProps.favoritePlacesAll !== this.props.favoritePlacesAll) {
        this.generateFavoritePlaces();
      }

    } catch (error) {
      console.error('megamap.didupdated', error);
    }
  }

  findDriverName = (item) => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          const theName = `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;

          return theName.length > 15
            ? theName.substring(0, 14) + '...'
            : theName;
        }
      }
    }
    return '';
  };

  isString = (x) => {
    return Object.prototype.toString.call(x) === '[object String]';
  };

  playAudio(vehcile, limit, speed, text) {
    if (this.state.muteAlert === true || !this.state.userInteracted) {
      return;
    }

    const user = this.props.user;
    getTextToSpeech(user.userToken, vehcile, limit, speed, text).then(
      (response) => {
        if (response && response.status === 'success') {
          try {
            setTimeout(() => {
              const audioEl = document.getElementsByClassName(
                'audio-element-live-map'
              )[0];
              if (audioEl) {
                audioEl.src = response.url;
                audioEl.load();
                audioEl.play();
              }
            }, 1300);
          } catch (error) {
            console.log('error.playAudio ', error);
          }
        }
      }
    ).catch((error) => {
      console.log('error.playAudio ', error);
    });
  }

  onInteracted = () => {
    this.setState({ userInteracted: true });
  };

  overSpeedMonitor = (vehicles) => {
    const vehicleDetails = vehicles || [];

    vehicleDetails.forEach((vehicle) => {
      const speedLimit = parseFloat(vehicle.speed_limit) || 0;
      const epic = vehicle.epic || {};

      const timeDiff = vehicle?.theTimeDiffAsSec || 100 ;
      const vehicleSpeed =  epic?.speed || 0;

      if ( 
        timeDiff < 600 &&
        vehicleSpeed > speedLimit ) {

        console.log('over speeding true for ', vehicle.fleet_number, {
          fresh: timeDiff < 60 ,
          timeDiff
        } );

        const isAdded = addAlertManagerItem(vehicle.autoid, 'OVER-SPEED');
        const vehcileName = vehicle.fleet_number
          ? vehicle.fleet_number
          : vehicle.plate_number;
        const text = `Over speed alert, The vehicle ${vehcileName} is speeding above ${speedLimit}kmph, Speed ${epic.speed}`;
       
        console.log('overSpeedMonitor confirmed', {isAdded});

        try{

           // BOOKMARKED
        if (isAdded) {
          console.log('overSpeed notify ', text);
          this.playAudio(vehcileName, speedLimit, epic.speed, text);
          NotificationManager.info(
            text,
            'OVER SPEED ALERT',
            25000,
            null,
            null,
            ''
          );
        }

        }catch(error){
          console.log('error.overSpeedMonitor ', error);
        }


      }
    });
  };

  attachLiveDetails = (liveUpdates) => {
    const v = this.state.selectedVehicles || [];

    const mapBoxes = this.state.mapBoxes || [];

    const timeNow = new Date();

    const geofencePoints = this.state.geofencePoints.map((x) => {
      return {
        ...x,
        vehicles: []
      }
    });

    const groupList = this.props.groups || [];

    // console.log('groupList ', groupList);

    let off = 0;
    let idle = 0;
    let running = 0;
    let noDataCount = 0;
    let expiredCount = 0;
    let expiryExtensionCount = 0;

    let vehicleState = 'no-data';

    const e = v.map((r) => {
      const liveEpic =
        liveUpdates.filter(
          (x) => x.vehicle_id === (r.autoid || r.key || 0)
        )[0] || {};


      const gList = [];
      groupList.forEach(gItem => {
        if (gItem.vehicleData.indexOf(r.key.toString()) !== -1) {
          gList.push(gItem);
          // console.log('a lot of ', gItem);
        }
      });

      const theMoment = moment(liveEpic.gpstime);
      const timeShort = TimeSince(theMoment.toDate());
      const timeHBMoment = moment(liveEpic.updatetime);

      const timeDiff = moment.duration(moment().diff(theMoment));

      const timeIdle =
        liveEpic.idle_time && timeDiff.asSeconds() < 200
          ? TimeSince(liveEpic.idle_time)
          : '';

      const isNoData = liveEpic.gpstime
        ? timeNow - new Date(liveEpic.updatetime) <
          this.liveMapNoDataInMillisecond
          ? false
          : true
        : true;

        //expiryExtensionCount
        if(r.reminigDays < 0 && r.expiryExtensionDays > 0){
          expiryExtensionCount ++;
        }

      if (!liveEpic.ignition) {
        if (r.reminigDays < 0 && r.expiryExtensionDays < 1 ){
          expiredCount++;
          vehicleState = 'expired';

        }else if (isNoData) {
          noDataCount++;
          vehicleState = 'no-data';
        } else {
          off++;
          vehicleState = 'off';
        }
      } else {
        if (r.reminigDays < 0 && r.expiryExtensionDays < 1 ){
          expiredCount++;
          vehicleState = 'expired';

        }else if (liveEpic.speed === 0) {
          idle++;
          vehicleState = 'idle';
        } else {
          running++;
          vehicleState = 'running';
        }
      }

      if (vehicleState === 'off' || vehicleState === 'no-data' || vehicleState === 'expired') {
        liveEpic.speed = 0;
      }

      liveEpic.noDataTime = this.liveMapNoDataInMillisecond;

      // TODO: R1: update geo points here
      const fenceEx = r.geofenceStand || getFencePlan(this.state.geofencePoints) || [];

      try {
        for (let fenceIndex = 0; fenceIndex < fenceEx.length; fenceIndex++) {
          // console.log('call points ',  [parseFloat(liveEpic.lat), parseFloat(liveEpic.lng)], fenceEx[fenceIndex].boundariesPoints);

          fenceEx[fenceIndex].liveStatus = isPolyInside(
            [parseFloat(liveEpic.lat), parseFloat(liveEpic.lng)],
            fenceEx[fenceIndex].boundariesPoints,
          );

          if (fenceEx[fenceIndex].liveStatus) {
            for (let index = 0; index < geofencePoints.length; index++) {

              if (geofencePoints[index].autoid === fenceEx[fenceIndex].autoid) {
                geofencePoints[index].vehicles.push(r);

              }

            }
          }
        }
      } catch (error) {
        console.log('fence Error 309', error);
      }

      return {
        ...r,
        geofenceStand: fenceEx,
        vehicleState,
        gpstime: liveEpic.gpstime,
        autoid: liveEpic.vehicle_id,
        showBox: mapBoxes.indexOf(r.autoid || r.key || 0) === -1 ? false : true,
        epic: liveEpic || {},
        noData: isNoData,
        driverName: this.findDriverName(liveEpic),
        theTime: this.isString(timeShort) ? timeShort : '',
        theTimeNow: this.isString(timeShort) ? timeShort : '',
        theMoment,
        timeHBMoment,
        theIdleTime: timeIdle === 'Now' ? '1m' : timeIdle,
        theTimeDiffAsSec: timeDiff.asSeconds(),
        groupList: gList,
      };
    });

    const geoVehicles = [];

    geofencePoints.forEach(element => {
      // geoVehicles.push(...element.vehicles);
      const a = element.vehicles.map((x) => {
        return {
          ...x,
          geofenceTitle: element.title,
          geofenceId: element.autoid
        }
      });

      geoVehicles.push(...a);
    });


    this.setState({ mapVehicles: e, off, idle, running, noDataCount, geofencePoints, geoVehicles, expiredCount, expiryExtensionCount });


    this.searchHandler(null, e);

    setTimeout(() => {
      try {
        
        this.overSpeedMonitor(e);
      } catch (error) {
        console.log('error.overSpeedMonitor ', error);
        
      }
    }, 10);
  };

  onGeofenceDelete = (id) => {
    const user = this.props.user;
    const userDetails = this.props.userDetails;

    console.log('userDetails.roleText ', userDetails.roleText);
    
    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can delete only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceDelete(user.userToken, id).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === 'success') {
            this.props.geofenceSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              'Success',
              3000,
              null,
              null,
              ''
            );
          } else {
            // console.error("geofence.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ''
            );
          }
        }
      }
    });
  };

  onGeofenceUpdate = (id, title, speed) => {
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can update only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }
    
    GeofenceUpdate(user.userToken, id, title, speed).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === 'success') {
            this.props.geofenceSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              'Success',
              3000,
              null,
              null,
              ''
            );
          } else {
            // console.error("geofence.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ''
            );
          }
        }
      }
    });
  };

  generateGeofencePoints = () => {

    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;

    // if user no geofence 
    if (userRole === 3) {
      return ; 
    }


    if (!this.state.map || !this.state.maps || !this.props.geofenceAll) {
      this.setState({ geofencePoints: [] });
      return;
    }

    const geofenceAll = this.props.geofenceAll || [];
    MapPolygon.updatePolygons(
      geofenceAll,
      this.state.map,
      this.state.maps,
      this.onGeofenceUpdate,
      this.onGeofenceDelete
    );

    // TODO: R1: Load geofence here
    this.setState({
      geofencePoints: geofenceAll.map((x) => {
        return {
          ...x,
          vehicles: []
        }
      })
    });
  };

  generateFavoritePlaces = () => {
    if (!this.state.map || !this.state.maps || !this.props.favoritePlacesAll) {
      this.setState({ favoritePlacePoints: [] });
      return;
    }

    const favoritePlacesAll = this.props.favoritePlacesAll || [];

    MapFavoritePlaces.updateMarkers(
      favoritePlacesAll,
      this.state.map,
      this.state.maps,
      this.onFavoritePlaceDelete
    );

    this.setState({ favoritePlacePoints: favoritePlacesAll });
  };


  onFavoritePlaceDelete = (id) => {
    const user = this.props.user;
    FavoritePlacesDelete(user.userToken, id).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === "success") {
            this.props.favoritePlacesSelectRequest(this.props.history);
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  onMapLoaded(map, maps) {
    this.setState({
      mapsLoaded: true,
      map: map,
      maps: maps,
    });

    this.megaMapHelper.setMap(map, maps);
    this.generateGeofencePoints();
    this.generateFavoritePlaces();

    if (
      this.props.selectedVehicles &&
      this.props.selectedVehicles.length &&
      this.props.selectedVehicles.length === 1
    ) {
      setTimeout(() => {
        this.megaMapHelper.setSelectedVehicle(
          this.props.selectedVehicles[0].key
        );
      }, 2200);
    }

    this.mapDrawing = MapDrawing.init(
      map,
      maps,
      this.OnMapDrawingCompleted,
      this.OnMapDrawingAddNew,
      this.OnMapDrawingAddNew_FavoritePlace
    );
  }



  OnMapDrawingAddNew_FavoritePlace = (title, geo, radius, typeText, type) => {
    // const points = geo;
    const user = this.props.user;
    FavoritePlaceAdd(user.userToken, title, geo, radius, typeText, type).then((response) => {
      if (response) {
        this.setState({ loading: false });

        if (response) {
          if (response.status === "success") {
            this.props.favoritePlacesSelectRequest(this.props.history);

            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
          } else {
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };


  OnMapDrawingCompleted = (pathPoints) => {
    // console.log("log ", this.props.geofence);
  };

  OnMapDrawingAddNew = (name, speed, coordinates) => {
    const points = coordinates.join('');
    const user = this.props.user;

    const userDetails = this.props.userDetails;

    console.log('userDetails.roleText ', userDetails.roleText);
    if(userDetails.roleText === "User" ){

      NotificationManager.error(
        'Geofence can add only by Owner or Admin',
        "Permission denied",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    GeofenceAdd(user.userToken, name, points, speed).then((response) => {
      if (response) {
        this.setState({ loading: false });
        if (response) {
          if (response.status === 'success') {
            this.props.geofenceSelectRequest(this.props.history);

            NotificationManager.success(
              response.message,
              'Success',
              3000,
              null,
              null,
              ''
            );
          } else {
            // console.error("geofence.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ''
            );
          }
        }
      }
    });
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.megaMapHelper.setSelectedMultipleVehicle(selectedVehicles);
  };

  onGeofenceSelect = (item) => {
    MapPolygon.toggle(item.autoid);
  };


  onFavoritePlacesSelectAll = (visibility) => {
    MapFavoritePlaces.showAll(visibility);
  };


  onFavoritePlacesSelect = (item) => {
    MapFavoritePlaces.toggle(item.autoid, true);
  };

  searchHandler = (event, mapVehicles) => {
    let searchValue = event
      ? event.target.value.toLowerCase()
      : this.state.searchText;

    if (!searchValue && mapVehicles) { // possible bug fix
      this.setState({
        filteredDataRows: mapVehicles || [],
        searchText: searchValue,
      });
      return;
    }

    let arrData = searchValue.split(' ');
    let tempDataRows = this.state.mapVehicles;
    let filteredDataRows = this.state.mapVehicles;
    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        return (
          el.plate_number.toLowerCase().indexOf(element) !== -1 ||
          el.fleet_number.toLowerCase().indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredDataRows: filteredDataRows || [],
      searchText: searchValue,
    });
  };

  handleOnClickVehicle = (autoid, mapAction) => {
    this.megaMapHelper.showVehicle(autoid, mapAction);

    const mapBoxes = this.state.mapBoxes || [];
    const mapVehicles = this.state.mapVehicles || [];
    const add = mapBoxes.indexOf(autoid) === -1 ? true : false;

    add ? mapBoxes.push(autoid) : mapBoxes.splice(mapBoxes.indexOf(autoid), 1);

    for (let i = 0; i < mapVehicles.length; i++) {
      if (mapVehicles[i].autoid === autoid) {
        mapVehicles[i].showBox = add;
        break;
      }
    }

    this.setState({ mapBoxes, mapVehicles });
  };

  toggleLarge = () => {
    this.setState((prevState) => ({
      mapModelOpen: !prevState.mapModelOpen,
    }));
  };

  render() {
    let mapOnly = this.props.mapOnly || false;
    const geofenceAll = this.state.geofencePoints || [];
    const favoritePlacesAll = this.state.favoritePlacePoints || [];
    const mapVehicles = this.state.mapVehicles || [];
    let vOne = mapVehicles[0] || { epic: {} };



    if (!this.state.showSideBar) {
      mapOnly = true;
    }

    vOne = vOne.noData ? undefined : vOne;

    return (
      <React.Fragment>
        <SuspensionModel />
        <MegaMapGeoVehiclesModel
          geoVehicles={this.state.geoVehicles}
          geofenceAll={geofenceAll}
          isOpen={this.state.mapModelOpen}
          closeButton={
            <Button
              color="primary"
              outline
              size="xs"
              className="mb-2"
              onClick={this.toggleLarge}
            >
              <IntlMessages id="common.close" />
            </Button>
          }
          toggle={this.toggleLarge}
        />
 
         

        {mapOnly && this.state.showSideBar ? (
          ''
        ) : (
          <Row className="live-tile m-0">
            <Colxx xl="2" lg="6" md="12" className="bg-primary">
              <IntlMessages id="common.total" /> {
                this.state.running + this.state.idle +
                this.state.off + this.state.noDataCount + this.state.expiredCount
              }
            </Colxx>
            <Colxx xl="2" lg="6" md="12" className="bg-success">
              <IntlMessages id="common.running" /> {this.state.running}
            </Colxx>
            <Colxx xl="2" lg="6" md="12" className="bg-danger">
              <IntlMessages id="common.idle" />  {this.state.idle}
            </Colxx>
            <Colxx xl="2" lg="6" md="12" className="bg-dark2">
              <IntlMessages id="common.off" /> {this.state.off}
            </Colxx>
            <Colxx xl="2" lg="6" md="12" className="bg-info">
              <IntlMessages id="common.no-data" /> {this.state.noDataCount}
            </Colxx>

            <Colxx xl="2" lg="6" md="12" className="bg-yellow">
              Expired {this.state.expiredCount + this.state.expiryExtensionCount}
            </Colxx>
          </Row>
        )}
        {this.props.mapOnly ? (
          <Row>
            <Colxx xxs="12">
              {vOne ? (
                <h4>
                  Engin {vOne.epic.ignition ? 'On' : 'Off'},{' '}
                  {vOne.epic.ignition ? vOne.epic.speed : '0'} kmph,{' '}
                  {vOne.theTime}, {vOne.driverName}{' '}
                  {vOne.epic.fuel > -1 ? 'Fuel ' + vOne.epic.fuel + ' ltr' : ''}
                </h4>
              ) : (
                <h4> <IntlMessages id="tracking.no-data-available" /> </h4>
              )}
            </Colxx>
          </Row>
        ) : (
          <Row>
            <Colxx xxs="12">
              <ButtonGroup className="flex-wrap">
                <Button
                  color="secondary"
                  size="xs"
                  outline
                  title="Show List"
                  onClick={() => {
                    this.setState({ showSideBar: !this.state.showSideBar });
                  }}
                >
                  {this.state.showSideBar ? (
                    <i className="iconsminds-arrow-right-2" />
                  ) : (
                    <i className="iconsminds-arrow-left-2" />
                  )}
                </Button>

                <React.Fragment>
                  <Button
                    color="secondary"
                    size="xs"
                    outline
                    title="Zoom In"
                    onClick={() => {
                      this.state.map.setZoom(this.state.map.getZoom() + 1);
                    }}
                  >
                    <i className="simple-icon-magnifier-add" />
                  </Button>
                  <Button
                    color="secondary"
                    size="xs"
                    outline
                    title="Zoom Out"
                    onClick={() => {
                      this.state.map.setZoom(this.state.map.getZoom() - 1);
                    }}
                  >
                    <i className="simple-icon-magnifier-remove" />
                  </Button>

                  <Button
                    color="secondary"
                    size="xs"
                    outline
                    title="Zoom reset"
                    onClick={() => {
                      this.state.map.setZoom(8);
                    }}
                  >
                    <i className="simple-icon-magnifier" />
                  </Button>

                  <Button
                    color={!this.state.muteAlert ? 'secondary' : 'dark'}
                    size="xs"
                    outline
                    title={
                      this.state.muteAlert === true
                        ? 'Sound alert'
                        : 'Mute alert'
                    }
                    onClick={() => {
                      localStorage.setItem(
                        'user_mute_alert',
                        !this.state.muteAlert
                      );
                      this.setState({ muteAlert: !this.state.muteAlert });
                    }}
                  >
                    {this.state.muteAlert === true ? (
                      <i className="iconsminds-loudspeaker" />
                    ) : (
                      <i className="simple-icon-volume-off" />
                    )}
                  </Button>

                  <Button
                    color="secondary"
                    size="xs"
                    outline
                    title="Show/Hide all map box"
                    onClick={() => {
                      const mapBoxes = this.state.mapBoxes || [];

                      if (mapBoxes.length) {
                        // Hide all boxes
                        const mapVehicles = this.state.mapVehicles || [];
                        for (let i = 0; i < mapVehicles.length; i++) {
                          mapVehicles[i].showBox = false;
                        }

                        this.setState({ mapBoxes: [], mapVehicles });
                      } else {
                        // Show all boxes
                        const mapVehicles = this.state.mapVehicles || [];
                        for (let i = 0; i < mapVehicles.length; i++) {
                          mapVehicles[i].showBox = true;
                        }

                        this.setState({
                          mapVehicles,
                          mapBoxes: this.state.mapVehicles.map((x) => x.autoid),
                        });
                      }
                    }}
                  >
                    <i className="simple-icon-speech" />
                  </Button>
                </React.Fragment>
              </ButtonGroup>

              <small>
                {this.state.userInteracted && !this.state.muteAlert
                  ? <IntlMessages id="tracking.voice-enabled" />
                  : ''}{' '}
              </small>

              <small className="float-right"> You are viewing on {momentTimeZone.tz.guess()} ({ moment().format('Z')}) time </small>
            </Colxx>
          </Row>
        )}

        <Row>
          <Colxx xl={mapOnly ? '12' : '10'} lg={mapOnly ? '12' : '10'} md="12">
            <div style={{ height: '518px' }} className="w-100">
              <GoogleMap
                yesIWantToUseGoogleMapApiInternals={true}
                bootstrapURLKeys={{
                  key: 'AIzaSyAKjw_wuep7sJmjM6tKylxLr6Yo_bHUxnw',
                  libraries: ['drawing', 'geometry'].join(','),
                }}
                defaultCenter={[25.273217, 51.414257]}
                defaultZoom={8}
                options={{ mapTypeControl: true }}
                onGoogleApiLoaded={({ map, maps }) => {
                  this.onMapLoaded(map, maps);
                }}
              >
                {mapVehicles.map((x) => {
                  const class1 = x.vehicleState;

                  if (!x.epic.lng || !x.showBox) {
                    return null;
                  }

                  if (this.props.mapOnly) {
                    return null;
                  }

                  return (
                    <LiveMapInfoBox
                      key={x.autoid}
                      lat={x.epic.lat}
                      lng={x.epic.lng}
                      map={this.state.map}
                      itemData={x}
                      class1={class1}
                      className={`d-inline-block tile-${class1} text-light p-1 border`}
                      onClick={() => {
                        // console.log('action from map box', x.autoid);
                      }}
                    ></LiveMapInfoBox>
                  );
                })}
              </GoogleMap>
            </div>
          </Colxx>

          {!mapOnly ? (
            <MegaMapSideList
              vehicles={this.state.filteredDataRows}
              searchText={this.state.searchText}
              onVehicleClick={this.handleOnClickVehicle}
              onSearchChange={this.searchHandler}
              locale={this.props.locale}
            />
          ) : (
            ''
          )}
        </Row>

         
        <div className="mt-2 mb-2">

          {(favoritePlacesAll.length ? {} : null) && (
            <div className="mt-2 mb-2">
              <strong className="text-muted">
                Favorite Places
              </strong>
              <Button
                className="ml-1"
                size="xs"
                onClick={() => {
                  this.onFavoritePlacesSelectAll(this.state.favoritePlacesVisibility);
                  this.setState({
                    favoritePlacesVisibility: !this.state.favoritePlacesVisibility,
                  });
                }}
              >
                {this.state.favoritePlacesVisibility ? (
                  <IntlMessages id="tracking.show-all" />
                ) : (
                  <IntlMessages id="tracking.hide-all" />
                )}
              </Button>
              <br />
              {favoritePlacesAll.map((item, index) => {
                return (
                  <Button
                    outline
                    color="primary"
                    size="xs"
                    key={index}
                    className="mr-1 mt-1"
                    onClick={() => this.onFavoritePlacesSelect(item)}
                  >
                    {item.title}
                  </Button>
                );
              })}
            </div>
          )}

          <strong className="text-muted"><IntlMessages id="common.geofence" /></strong>
          <br />
          {geofenceAll.map((item, index) => {
            return (
              <Button
                outline
                color="primary"
                size="xs"
                key={index}
                className="mr-1 mb-1"
                onClick={() => this.onGeofenceSelect(item)}
              >
                {item.title} ({item.vehicles.length})
              </Button>
            );
          })}

        </div>

        <Button
          outline
          color="link"
          size="xs"
          key={'-s'}
          className="mr-1 mb-1"
          onClick={this.toggleLarge}
        >
          Show Geofence Vehicles
        </Button>



        <audio className="audio-element-live-map">
          <source src="https://files.ctraac.com/app-audios/over-speeds/juntos.mp3"></source>
        </audio>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser, geofence, drivers, subscriber, settings, groups, favoritePlaces }) => {
  const { user, details  } = authUser;
  const { items } = geofence;
  const driversAll = drivers.items || [];
  const subscriberDetails = subscriber.details;
  const { locale } = settings;

  const favoritePlacesAll = favoritePlaces.items || [];

  return {
    user, geofenceAll: items, driversAll,
    subscriberDetails: subscriberDetails, locale,
    groups: groups.items, favoritePlacesAll,
    userDetails : details
  };
};

export default connect(mapStateToProps, {
  geofenceSelectRequest,
  favoritePlacesSelectRequest
})(withRouter(MegaMap));
